@import "../../routes/Vars.scss";

.teamwork{
    display: flex;
    justify-content: center;
    margin: 0;
    text-align: center;
    padding: 20px;
    .teamwork__container{
        width: 300px;
        background-color: white;
        padding: 20px;
        border-radius: 15px;
        .teamwork__image{
            height: 340px;
            .teamwork__photo{
                width: 250px;
                height: 340px;
                background: rgba($color: #000000, $alpha: 0.1);
                padding: 25px 0 0 0;
                border-radius: 15px;
            }
            .teamwork__logo{
                width: 50px;
                position: relative;
                top: -70px;
                right: -90px;
            }
        }
        &>h4{
            padding-top: 20px;
            font-weight: bold;
            color:$primary-color;;
            margin: 1px;
        }
        &>h5{
            padding-top: 10px;
            letter-spacing: 4px;
            font-weight: lighter;
            color: $secondary-color;
            margin-top: 5px;
        }
    }
}

@media only screen and (max-width: 1000px) {

}

@media only screen and (max-width: 400px) {
    .teamwork{
        .teamwork__container{
            width: 250px;
            .teamwork__image{
                height: 290px;
                .teamwork__photo{
                    width: 200px;
                    height: 290px;
                }
                .teamwork__logo{
                    width: 40px;
                    top: -55px;
                    right: -75px;
                }
            }
            &>h4{
                font-size:medium;           
             }
            &>h5{
                font-size:small;  
            }
        }
    }
}