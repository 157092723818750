@import "../../routes/Vars.scss";

.categories{
    display: flex;
    justify-items: center;
    flex-wrap: wrap;
    flex-shrink: 0;
    padding: 20px 0;
}
.isService{
    position: relative;
    display: flex;
    justify-content: center;
    text-align: center;
}
.isTeamwork{
    background-color: rgba(240,240,240,255);
    position: relative;
    display: flex;
    justify-content: center;
    text-align: center;
}

.categories__title {
    width: 100%;
    text-align: center;
    color:$primary-color;
    font-weight: bold;
    letter-spacing: 5px;
}

@media only screen and (min-width: 1200px) {
    .isService{
        padding: 20px 10%;
    }
}
@media only screen and (max-width: 700px) {
    .isTeamwork{
        display: none;
    }
}