@import url(https://fonts.googleapis.com/css?family=Muli&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fjalla+One&family=Montserrat:ital,wght@0,400;0,600;0,700;0,900;1,300;1,400;1,600;1,700;1,900&display=swap);
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700');
@import url('https://fonts.googleapis.com/css?family=Anton');
@import './Vars.scss';

body {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: 'Montserrat';
}

h1 {
  font-weight: bold;
}

label{
  color: white;
}
  
* {
  box-sizing: border-box;
}

@media only screen and (max-width: 1000px) {
h2{
  font-size:larger;
  }
}